<template>
  <section>
    <a-form class="form-container" :form="form" @submit="submitForm">
      <a-row type="flex" :gutter="20">
        <a-col :span="8">
          <a-form-item label="采购订单编号">
            <!-- {
                  rules: [{ required: true, message: '请选择采购订单编号' }]
                } -->
            <a-select
              placeholder="请选择"
              @change="changeCode"
              :disabled="selectedRowKeys.length > 0"
              v-decorator="['orderId']"
            >
              <a-select-option
                v-for="item in getCodeListData"
                :key="item.id"
                :value="item.id"
              >
                {{ item.code }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <!--  {
                  rules: [{ required: true, message: '请选择仓库' }]
                } -->
          <a-form-item label="批次订单编号">
            <a-select
              placeholder="请选择"
              @change="changeBatchCode"
              :disabled="selectedRowKeys.length > 0"
              v-decorator="['batchId']"
            >
              <a-select-option
                v-for="item in getBatchCodeListData"
                :key="item.id"
                :value="item.id"
              >
                {{ item.code }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="入库时间 ">
            <a-date-picker
              v-decorator="[
                'inTime',
                {
                  rules: [{ required: true, message: '请填写入库时间' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="20">
        <!-- <a-col :span="8">
          <a-form-item label="经办人 ">
            <a-input disabled defaultValue="sasd"></a-input>
          </a-form-item>
        </a-col> -->
        <a-col :span="8">
          <a-form-item label="送货人">
            <a-input
              autocomplete="off"
              v-decorator="[
                'deliveryman',
                {
                  rules: [
                    { required: true, message: '请填写送货人' },
                     { max: 30, message: '最大输入30个汉字' },
                  ],
                },
              ]"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="入库单据编号">
            <a-input
              autocomplete="off"
              v-decorator="[
                'code',
                {
                  rules: [{ required: true, message: '请填写入库单据编号' }],
                },
              ]"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="供应商">
            <a-input
              autocomplete="off"
              v-decorator="[
                'supplier',
                {
                  rules: [
                    { required: true, message: '请填写供应商' },
                    { max: 30, message: '最大输入30个汉字' },
                  ],
                },
              ]"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="20">
        <!-- <a-col :span="8">
          <a-form-item label="供货商">
            <a-input></a-input>
          </a-form-item>
        </a-col> -->
        <a-col :span="8">
          <a-form-item label="所属仓库">
            <a-select
              placeholder="请选择"
              @change="changeWarehouse"
              v-decorator="[
                'warehouseId',
                {
                  rules: [{ required: true, message: '请选择仓库' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in warehouseData"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <section class="list-table-container list-table">
        <a-table
          :bordered="false"
          :data-source="dataSource"
          :pagination="false"
          size="small"
          class="m-t-18"
          :columns="columns"
        >
          <!-- <template slot="batchQty" slot-scope="list, text">
            <a-input
              :min="0"
              :max="text.batchQty"
              v-model="text.batchQty"
              type="number"
            />
          </template> -->
          <template slot="batch_qty" slot-scope="list, text">
            <a-input :min="0" v-model="text.batch_qty" type="number" />
          </template>
          <template slot="operation" slot-scope="list, text">
            <a v-if="!text.batchId" @click="delDataSource(text.id)">删除</a>
          </template>
        </a-table>
        <a-row
          v-if="addVisible"
          class="search-container addData"
          type="flex"
          justify="center"
        >
          <a-button @click="addData">新增</a-button>
        </a-row>
        <a-row class="totalNum" type="flex" justify="start">
          <span style="width: 100px">合计</span>

          <span style="width: 200px"></span>
          <span style="width: 200px"></span>
          <span style="width: 200px"></span>
          <span style="width: 200px"></span>
          <span style="width: 200px">{{ batchQty }}</span>
          <span style="width: 200px">{{ batch_qty }}</span>
          <span style="width: 200px"></span>
        </a-row>
      </section>

      <a-row type="flex" :gutter="20">
        <a-col :span="24">
          <a-form-item label="入库照片">
            <!-- <a-upload
              list-type="picture-card"
              :beforeUpload="beforeUploadFiles"
              :file-list="fileList"
            >
              <div v-if="fileList.length < 3">
               
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload> -->
            <a-row type="flex">
              <upload
                style="display: inline-block; width: 100px"
                v-decorator="[
                  'images',
                  { rules: [{ required: false, message: '请上传照片' }] },
                ]"
                :node="{ type: 3, text: '上传' }"
                :accept="['.png', '.jpg']"
                :uploadUp="uploadUpMore"
                :loadData="loadDataArr"
                @func="uploadFile"
              />
            </a-row>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="fileList">
        <a v-if="files.length > 0" class="file-item m-r-10">
          <span
            v-for="(item, index) in files"
            :key="index"
            style="position: relative"
          >
            <a-icon class="delFile" type="close" @click="delFile(item.url)" />
            <a-avatar shape="square" :size="100" :src="item.url" />
          </span>
          <!-- <a-avatar shape="square" :size="100" :src="files.url" /> -->
        </a>
      </a-row>
      <a-row type="flex" :gutter="20">
        <a-col :span="24">
          <a-form-item label="备注">
            <a-textarea v-model="remarks" rows="4"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-btn-group">
        <a-col :span="8">
          <a-button html-type="submit"><a-icon type="save" />保存</a-button>
          <a-button @click="$router.back()">取消</a-button>
        </a-col>
      </a-row>
    </a-form>
    <!-- 模态框 -->
    <a-modal
      centered
      width="880px"
      class="modal-container"
      :title="typeModal.title"
      v-model="typeModal.visible"
      :destroyOnClose="true"
      :footer="false"
    >
      <a-form :form="materialform" @submit="addMaterial">
        <a-form-item
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 20 }"
          label="材料"
        >
          <a-cascader
            :options="options"
            @change="(value) => onChangeMaterial(value, options)"
            placeholder="请选择"
            :display-render="displayRender"
            v-decorator="[
              'companyNames',
              { rules: [{ required: true, message: '类型名称不能为空' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 20 }"
          label="分类名称"
        >
          <section class="list-table-container list-table">
            <a-table
              :rowSelection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :bordered="false"
              :data-source="materialSource"
              size="small"
              class="m-t-36"
              :columns="materialColumns"
              :pagination="false"
              :scroll="{ y: 500 }"
            >
            </a-table>
          </section>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" html-type="submit"
            ><a-icon type="save" />保存</a-button
          >
          <a-button class="btn-cancel" @click="typeModal.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
  </section>
</template>

<script>
import moment from "moment";
import upload from "@/components/upload";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      materialform: this.$form.createForm(this),
      materialPageNum: 1,
      materialTotal: 0,
      selectedRowKeys: [],
      materialColumns: [
        { title: "序号", dataIndex: "order", align: "center" },
        { title: "品牌", dataIndex: "brand", align: "center" },
        { title: "规格型号", dataIndex: "type", align: "center" },
        { title: "库存数量", dataIndex: "stockQty", align: "center" },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
        },
      ],
      materialSource: [],
      typeModal: {
        visible: false,
        title: "添加",
      },
      uploadUpMore: "uploadUpMore",
      totaldataSource: [],
      options: [],
      addMaterialName: "", //新增材料名称
      warehouseData: [], //仓库
      getCodeListData: [], //订单编号
      getBatchCodeListData: [], //批次订单号
      getBatchCodeList: [], //批次编号
      warehouseId: "", //仓库id
      orderId: "", //采购单ID
      batchId: "",
      code: "", //批次id
      addVisible: true, //新增
      remarks: "",
      columns: [
        { title: "序号", dataIndex: "order", align: "center", width: 100 },
        {
          title: "材料名称",
          dataIndex: "name",
          align: "center",
          width: 200,
          // scopedSlots: { customRender: "material" }
        },
        {
          title: "品牌",
          dataIndex: "brand",
          align: "center",
          width: 200,
          // scopedSlots: { customRender: "brand" }
        },
        {
          title: "规格型号",
          dataIndex: "type",
          align: "center",
          width: 200,
          // scopedSlots: { customRender: "type" }
        },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
          width: 200,
          // scopedSlots: { customRender: "unit" }
        },
        {
          title: "送货数量",
          dataIndex: "batchQty",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "batchQty" },
        },
        {
          title: "入库数量",
          dataIndex: "batch_qty",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "batch_qty" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      dataSource: [],

      skuID: "",
      files: [],
      fileList: [],
      loadDataArr: [],
      handerStatus: false,
    };
  },

  beforeCreate() {},
  created() {
    this.getInStorageNextCode(); // 入库编码
    this.warehouse(); //仓库下拉;
    this.getCodeList(); //采购订单编号
  },
  computed: {
    // 送货数量
    batchQty() {
      let d = 0;
      this.dataSource.forEach((item) => {
        d = (item.batchQty ? parseInt(item.batchQty) : 0) + d;
      });
      return d;
    },
    // 入库数量
    batch_qty() {
      let d = 0;
      this.dataSource.forEach((item) => {
        d = (item.batch_qty ? parseInt(item.batch_qty) : 0) + d;
      });
      return d;
    },
  },
  components: {
    upload,
  },
  methods: {
    // 材料分页
    changePageNum(p) {
      this.materialPageNum = p.current;

      this.getSkuList();
    },
    // 删除材料
    delDataSource(id) {
      // console.log(this.selectedRowKeys,id)
      this.dataSource = this.dataSource.filter((item) => {
        if (item.id != id) return item;
      });
      this.dataSource.forEach((item, index) => {
        item.order = index + 1;
      });
      this.selectedRowKeys = this.selectedRowKeys.filter((item) => {
        if (item != id) return item;
      });
      if (this.selectedRowKeys.length == 0) this.handerStatus = false;
      console.log(this.selectedRowKeys, "选择");
    },
    delFile(url) {
      // console.log(url,this.files);
      this.files = this.files.filter((item) => {
        if (url != item.url) return item;
      });
      this.loadDataArr = this.files;
      let uploadArr = [];
      this.loadDataArr.forEach((item) => {
        uploadArr.push(item.url);
      });
      this.$nextTick(() => {
        this.form.setFieldsValue({
          images: uploadArr.join(","),
        });
      });
    },
    moment,
    // 上传
    uploadFile(file) {
      this.files = file;
      console.log(file);
      this.loadDataArr = file;
      let uploadArr = [];
      file.forEach((item) => {
        uploadArr.push(item.codeUrl);
      });
      this.$nextTick(() => {
        this.form.setFieldsValue({
          images: uploadArr.join(","),
        });
      });
    },
    // 添加保存材料
    addMaterial(e) {
      e && e.preventDefault();
      this.materialform.validateFields((err, values) => {
        if (!err) {
          // 当前材料id
          let nowmaterialId =
            values.companyNames[values.companyNames.length - 1];
          this.options.forEach((item) => {
            if (item.id == nowmaterialId) {
              this.addMaterialName = item.name;
              return;
            }
            if (item.children && item.children.length > 0) {
              item.children.forEach((items) => {
                if (items.id == this.categoryId) {
                  this.addMaterialName = items.name;
                  return;
                }
              });
            }
          });

          this.selectedRowKeys.forEach((item) => {
            this.materialSource.forEach((v) => {
              if (item == v.key) {
                this.dataSource.push(v);
              }
            });
          });
          let uniq = this.uniq(this.dataSource, "id");
          this.dataSource = uniq;
          this.dataSource.forEach((item, index) => {
            item.order = index + 1;
          });
          // this.selectedRowKeys.forEach((item) => {
          //   this.materialSource.forEach((v) => {
          //     if (item == v.key) {
          //       this.dataSource.push(v);
          //     }
          //   });
          // });
          // this.dataSource.forEach((item, index) => {
          //   item.order = index + 1;
          // });
          // let uniq = this.uniq(this.totaldataSource, "type");
          // console.log(this.dataSource);
          console.log(this.selectedRowKeys, "选择");
          // this.dataSource = uniq;
          this.typeModal.visible = false;
        }
      });
    },
    // 保存新加
    submitForm(e) {
      e && e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          let params = {};

          params.code = values.code;
          params.inTime = values.inTime
            ? values.inTime.format("YYYY-MM-DD hh:mm:ss")
            : "";

          params.warehouseId = values.warehouseId;
          params.batchId = values.batchId;
          params.deliveryman = values.deliveryman;
          params.remarks = this.remarks;
          params.detailList = [];
          params.supplier = values.supplier;
          params.orderId = values.orderId;
          params.images = values.images;

          if (this.handerStatus == true) {
            this.dataSource.forEach((item) => {
              params.detailList.push({
                skuId: item.id,
                receivedQty: parseInt(item.batch_qty)
                  ? parseInt(item.batch_qty)
                  : "0",
              });
            });
          } else {
            this.dataSource.forEach((item) => {
              params.detailList.push({
                batchDetailId: item.id,
                skuId: item.skuId,
                receivedQty: parseInt(item.batch_qty)
                  ? parseInt(item.batch_qty)
                  : "0",
              });
            });
          }
          if (params.detailList.length == 0) {
            this.$message.error("请填写材料信息进行入库");
            return;
          }
          console.log(params);
          this.$api.addInStorage(params).then((res) => {
            if (res.code == "200") {
              this.$router.push({ path: "/mms/inout/warehouseIn/list" });
            }
          });
        }
      });
    },
    // 入库编码
    getInStorageNextCode() {
      this.$api.getInStorageNextCode().then((res) => {
        if (res.code == "200") {
          this.code = res.data;
          // 初始化 编码和时间
          this.form.setFieldsValue({
            inTime: moment(new Date(), "YYYY/MM/DD"),
            code: this.code,
          });
        }
      });
    },
    // 采购订单编号
    getCodeList() {
      this.$api.getCodeList().then((res) => {
        if (res.code == "200") {
          this.getCodeListData = res.data ? res.data : [];
          this.dataSource = [];
        }
      });
    },
    // 批次订单号列表
    getBatchsCodeList(id) {
      this.$api.getBatchCodeList({ orderId: id }).then((res) => {
        if (res.code == "200") {
          // if (res.data) {
          //   this.getBatchCodeListData = res.data ? res.data : [];
          //   this.dataSource = [];
          // }
          // 清空材料表格数据
          this.selectedRowKeys = [];
          this.getBatchCodeListData = res.data ? res.data : [];
          // console.log(this.getBatchCodeListData);
          // 默认查第一条
          if (this.getBatchCodeListData.length > 0) {
            this.changeBatchCode(this.getBatchCodeListData[0].id);
            this.form.setFieldsValue({
              batchId: this.getBatchCodeListData[0].id,
            });
            this.addVisible = false;
          } else {
            this.dataSource = [];
            this.form.setFieldsValue({
              batchId: "",
            });
            this.addVisible = false;
          }

          // if (this.getBatchCodeListData.length == 0) {
          // }
          // 清空
          // this.dataSource = [];
          // this.form.setFieldsValue({
          //   batchId: "",
          // });
        }
      });
    },
    // 选择订单编号
    changeCode(val) {
      this.orderId = val;
      this.getBatchsCodeList(this.orderId);
    },
    // 选择批次编号
    changeBatchCode(val) {
      this.batchId = val;
      this.getBatchDetail(val);
    },
    // 材料下拉
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    // 批次详情
    getBatchDetail(id) {
      this.$api.getBatchDetail(id).then((res) => {
        console.log(res);
        if (res.code == "200") {
          // 回填送货人 供货商
          this.form.setFieldsValue({
            deliveryman: res.data.deliveryman ? res.data.deliveryman : "",
            supplier: res.data.supplier ? res.data.supplier : "",
          });
          if (res.data.detailList && res.data.detailList.length > 0) {
            // this.addVisible = false;
            res.data.detailList.forEach((item, index) => {
              item.key = item.id;
              item.order = index + 1;
              item.batch_qty = 0;
            });

            this.dataSource = res.data.detailList;
          } else {
            this.dataSource = [];
            // this.addVisible = true;
          }
        }
      });
    },

    changeWarehouse(val) {
      this.warehouseId = val;
    },
    // 仓库下拉列表
    warehouse() {
      this.$api.warehouseData().then((res) => {
        if (res.code == "200") {
          this.warehouseData = res.data ? res.data : [];
        }
      });
    },
    // 选择添加材料
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 新增
    addData() {
      this.typeModal.visible = true;
      this.materialSource = [];
      // 用来处理判断保存的数据状态
      this.handerStatus = true;
      // this.selectedRowKeys = [];
      // 查询材料
      this.$api.getCategoryList().then((res) => {
        if (res.code == "200") {
          let options = res.data;
          let arr = this.formTreeData(options);
          this.options = arr;
        }
      });
    },
    // 树状图
    formTreeData(arr) {
      arr.forEach((item) => {
        item.value = item.id;
        item.label = item.name;
        // item.scopedSlots = { title: "value" };
        if (item.children && item.children.length > 0) {
          this.formTreeData(item.children);
        }
      });
      return arr;
    },
    uniq(arr, key) {
      let newobj = {};
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        var item = arr[i];
        if (!newobj[item[key]]) {
          newobj[item[key]] = newArr.push(item);
        }
      }
      return newArr;
    },
    // 选择材料
    onChangeMaterial(value) {
      this.categoryId = value[value.length - 1];
      this.getSkuList();
      //console.log(this.categoryId, list, this.warehouseId);
    },

    getSkuList() {
      this.$api
        .getSkuList({
          categoryId: this.categoryId,
          current: this.materialPageNum,
          size: 10000,
        })
        .then((res) => {
          if (res.code == "200") {
            let records = res.data.records;
            this.materialTotal = res.data.total;
            if (records && records.length > 0) {
              // console.log(this.selectedRowKeys);
              // if (this.selectedRowKeys.length > 0) {
              // console.log(records,this.selectedRowKeys)
              //   let arr = this.array_diff(records, this.selectedRowKeys); //去重
              //   arr.forEach((item, index) => {
              //     item.order = index + 1;
              //     item.key = item.id;
              //     item.skuID = item.id;
              //     item.num = 0;
              //     item.orderQty = 0;
              //     item.oriUnitPrice = 0;
              //     item.discount = 0;
              //     item.factPrice = 0;
              //     item.actualAmount = 0;
              //     item.actualPrice = 0;
              //   });
              //   this.materialSource = arr;
              // } else {
              //   records.forEach((item, index) => {
              //     item.order = index + 1;
              //     item.key = item.id;
              //     item.skuID = item.id;
              //     item.num = 0;
              //     item.orderQty = 0;
              //     item.oriUnitPrice = 0;
              //     item.discount = 0;
              //     item.factPrice = 0;
              //     item.actualAmount = 0;
              //     item.actualPrice = 0;
              //   });
              records.forEach((item, index) => {
                item.order = index + 1;
                item.key = item.id;
                item.skuID = item.id;
                item.num = 0;
                item.orderQty = 0;
                item.oriUnitPrice = 0;
                // item.discountStatus = "false";
                item.actualAmountStatus = "false";
                item.orderQtyStatus = "false";
                item.oriUnitPriceStatus = "false";
                item.discount = 0;
                item.factPrice = 0;
                item.actualAmount = 0;
                item.actualPrice = 0;
              });

              this.materialSource = records;
            }
          }
        });
    },
    // 数组去重
    array_diff(a, b) {
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].id == b[i]) {
            a.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return a;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-input[disabled] {
  color: rgba(255, 255, 255, 0.8);
  background: #3f72ba;
  cursor: not-allowed;
  opacity: 1;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker-label {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker {
  background-color: #1c3d6c;
  border: none;
  outline: none;
}
/deep/.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #1c3d6c;
}
.addData {
  padding: 8px 0;
  border: 1px solid #1c3d6c;
  border-top: 0;
  //   border-bottom: 0;
}
.totalNum {
  border: 1px solid #1c3d6c;
  border-top: 0;
  height: 46px;
  span {
    width: 217px;
    text-align: center;
    color: #93bbf3;
    height: 46px;
    line-height: 46px;
  }
}

.textarea,
.form-container {
  margin-top: 40px;
}
.fileList {
  .delFile {
    position: absolute;
    color: #fff;
    right: 4px;
    z-index: 3;
    top: -32px;
  }
}
/deep/.list-table .ant-table {
  color: #93bbf3;
}
/deep/.ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th {
  color: #fff;
}
/deep/.ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td {
  background: #1c3d6c;
}
</style>