var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a-form',{staticClass:"form-container",attrs:{"form":_vm.form},on:{"submit":_vm.submitForm}},[_c('a-row',{attrs:{"type":"flex","gutter":20}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"采购订单编号"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderId']),expression:"['orderId']"}],attrs:{"placeholder":"请选择","disabled":_vm.selectedRowKeys.length > 0},on:{"change":_vm.changeCode}},_vm._l((_vm.getCodeListData),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.code)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"批次订单编号"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['batchId']),expression:"['batchId']"}],attrs:{"placeholder":"请选择","disabled":_vm.selectedRowKeys.length > 0},on:{"change":_vm.changeBatchCode}},_vm._l((_vm.getBatchCodeListData),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.code)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"入库时间 "}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'inTime',
              {
                rules: [{ required: true, message: '请填写入库时间' }],
              },
            ]),expression:"[\n              'inTime',\n              {\n                rules: [{ required: true, message: '请填写入库时间' }],\n              },\n            ]"}]})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":20}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"送货人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'deliveryman',
              {
                rules: [
                  { required: true, message: '请填写送货人' },
                   { max: 30, message: '最大输入30个汉字' },
                ],
              },
            ]),expression:"[\n              'deliveryman',\n              {\n                rules: [\n                  { required: true, message: '请填写送货人' },\n                   { max: 30, message: '最大输入30个汉字' },\n                ],\n              },\n            ]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"入库单据编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [{ required: true, message: '请填写入库单据编号' }],
              },
            ]),expression:"[\n              'code',\n              {\n                rules: [{ required: true, message: '请填写入库单据编号' }],\n              },\n            ]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"供应商"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'supplier',
              {
                rules: [
                  { required: true, message: '请填写供应商' },
                  { max: 30, message: '最大输入30个汉字' },
                ],
              },
            ]),expression:"[\n              'supplier',\n              {\n                rules: [\n                  { required: true, message: '请填写供应商' },\n                  { max: 30, message: '最大输入30个汉字' },\n                ],\n              },\n            ]"}],attrs:{"autocomplete":"off"}})],1)],1)],1),_c('a-row',{attrs:{"type":"flex","gutter":20}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"所属仓库"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'warehouseId',
              {
                rules: [{ required: true, message: '请选择仓库' }],
              },
            ]),expression:"[\n              'warehouseId',\n              {\n                rules: [{ required: true, message: '请选择仓库' }],\n              },\n            ]"}],attrs:{"placeholder":"请选择"},on:{"change":_vm.changeWarehouse}},_vm._l((_vm.warehouseData),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1),_c('section',{staticClass:"list-table-container list-table"},[_c('a-table',{staticClass:"m-t-18",attrs:{"bordered":false,"data-source":_vm.dataSource,"pagination":false,"size":"small","columns":_vm.columns},scopedSlots:_vm._u([{key:"batch_qty",fn:function(list, text){return [_c('a-input',{attrs:{"min":0,"type":"number"},model:{value:(text.batch_qty),callback:function ($$v) {_vm.$set(text, "batch_qty", $$v)},expression:"text.batch_qty"}})]}},{key:"operation",fn:function(list, text){return [(!text.batchId)?_c('a',{on:{"click":function($event){return _vm.delDataSource(text.id)}}},[_vm._v("删除")]):_vm._e()]}}])}),(_vm.addVisible)?_c('a-row',{staticClass:"search-container addData",attrs:{"type":"flex","justify":"center"}},[_c('a-button',{on:{"click":_vm.addData}},[_vm._v("新增")])],1):_vm._e(),_c('a-row',{staticClass:"totalNum",attrs:{"type":"flex","justify":"start"}},[_c('span',{staticStyle:{"width":"100px"}},[_vm._v("合计")]),_c('span',{staticStyle:{"width":"200px"}}),_c('span',{staticStyle:{"width":"200px"}}),_c('span',{staticStyle:{"width":"200px"}}),_c('span',{staticStyle:{"width":"200px"}}),_c('span',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.batchQty))]),_c('span',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.batch_qty))]),_c('span',{staticStyle:{"width":"200px"}})])],1),_c('a-row',{attrs:{"type":"flex","gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"入库照片"}},[_c('a-row',{attrs:{"type":"flex"}},[_c('upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'images',
                { rules: [{ required: false, message: '请上传照片' }] },
              ]),expression:"[\n                'images',\n                { rules: [{ required: false, message: '请上传照片' }] },\n              ]"}],staticStyle:{"display":"inline-block","width":"100px"},attrs:{"node":{ type: 3, text: '上传' },"accept":['.png', '.jpg'],"uploadUp":_vm.uploadUpMore,"loadData":_vm.loadDataArr},on:{"func":_vm.uploadFile}})],1)],1)],1)],1),_c('a-row',{staticClass:"fileList"},[(_vm.files.length > 0)?_c('a',{staticClass:"file-item m-r-10"},_vm._l((_vm.files),function(item,index){return _c('span',{key:index,staticStyle:{"position":"relative"}},[_c('a-icon',{staticClass:"delFile",attrs:{"type":"close"},on:{"click":function($event){return _vm.delFile(item.url)}}}),_c('a-avatar',{attrs:{"shape":"square","size":100,"src":item.url}})],1)}),0):_vm._e()]),_c('a-row',{attrs:{"type":"flex","gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{attrs:{"rows":"4"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})],1)],1)],1),_c('a-row',{staticClass:"form-btn-group"},[_c('a-col',{attrs:{"span":8}},[_c('a-button',{attrs:{"html-type":"submit"}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v("保存")],1),_c('a-button',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("取消")])],1)],1)],1),_c('a-modal',{staticClass:"modal-container",attrs:{"centered":"","width":"880px","title":_vm.typeModal.title,"destroyOnClose":true,"footer":false},model:{value:(_vm.typeModal.visible),callback:function ($$v) {_vm.$set(_vm.typeModal, "visible", $$v)},expression:"typeModal.visible"}},[_c('a-form',{attrs:{"form":_vm.materialform},on:{"submit":_vm.addMaterial}},[_c('a-form-item',{attrs:{"label-col":{ span: 2 },"wrapper-col":{ span: 20 },"label":"材料"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'companyNames',
            { rules: [{ required: true, message: '类型名称不能为空' }] },
          ]),expression:"[\n            'companyNames',\n            { rules: [{ required: true, message: '类型名称不能为空' }] },\n          ]"}],attrs:{"options":_vm.options,"placeholder":"请选择","display-render":_vm.displayRender},on:{"change":(value) => _vm.onChangeMaterial(value, _vm.options)}})],1),_c('a-form-item',{attrs:{"label-col":{ span: 2 },"wrapper-col":{ span: 20 },"label":"分类名称"}},[_c('section',{staticClass:"list-table-container list-table"},[_c('a-table',{staticClass:"m-t-36",attrs:{"rowSelection":{
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange,
            },"bordered":false,"data-source":_vm.materialSource,"size":"small","columns":_vm.materialColumns,"pagination":false,"scroll":{ y: 500 }}})],1)]),_c('a-row',{staticClass:"form-btn-group"},[_c('a-button',{staticClass:"btn-save",attrs:{"html-type":"submit"}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v("保存")],1),_c('a-button',{staticClass:"btn-cancel",on:{"click":function($event){_vm.typeModal.visible = false}}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }